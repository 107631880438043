import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import MyForm from "../components/myForm"
import { Nav, Row } from "react-bootstrap"
import { IconContext } from "react-icons"
import { FaLinkedin, FaGithub } from "react-icons/fa"

const ContactPage = () => {
  return (
    <div>
      <Layout>
        <Head title="Contact" />
        <MyForm />
        <Row className="justify-content-around d-flex pt-5">
          <IconContext.Provider
            value={{ color: "white", size: "6rem", className: "m-0" }}
          >
            <Nav.Link
              href="https://www.linkedin.com/in/rooftb/"
              target="_blank"
              rel="noopener noreferrer"
              className="p-0"
            >
              <FaLinkedin />{" "}
            </Nav.Link>{" "}
            <Nav.Link
              href="https://github.com/rooftb"
              target="_blank"
              rel="noopener noreferrer"
              className="p-0"
            >
              <FaGithub />
            </Nav.Link>
          </IconContext.Provider>
        </Row>
      </Layout>
    </div>
  )
}

export default ContactPage
