import React from "react"
import { Form, Button } from "react-bootstrap"

export default class MyForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
    }
  }

  render() {
    const { status } = this.state
    return (
      <Form
        className="px-5 p-xs-0"
        onSubmit={this.submitForm}
        action="https://formspree.io/f/xvojzdqb"
        method="POST"
      >
        <Form.Group controlId="formBasicName">
          <Form.Label className="text-white">Name</Form.Label>
          <Form.Control type="text" name="name" placeholder="Enter name" />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label className="text-white">Email</Form.Label>
          <Form.Control type="email" name="email" placeholder="Enter email" />
        </Form.Group>

        <Form.Group controlId="formBasicMessage">
          <Form.Label className="text-white">Message</Form.Label>
          <Form.Control
            as="textarea"
            rows="8"
            name="message"
            placeholder="Enter message"
          />
        </Form.Group>

        {status === "SUCCESS" ? (
          <p className="text-center">Thanks!</p>
        ) : (
          <Button variant="info" type="submit" block>
            Submit
          </Button>
        )}
        {status === "ERROR" && (
          <p className="pt-3 text-center">Sorry! There was an error.</p>
        )}
      </Form>
    )
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }
}
